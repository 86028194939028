$globalFont: 'Inter', sans-serif;
$globalLineHeight: 1.167;
$globalFontSizeBig: 2.0625rem;

$background-color: #FFFFFF;
$foreground-color: rgba(77, 201, 242, 0.4);
$accent: #006891;
$accentHover: #005b7e;
$accentSecondary: #CCFF00;
$accentLight: rgba(0, 104, 145, 0.7);
$accentLightest: rgba(0, 104, 145, 0.3);
$accentSecondaryHover: #abd705;
$accentSecondaryLightest: rgba(204, 255, 0, 0.3);

$colorRed: #DB2955;
$colorGreen: #2E933C;

$colorBlack: #000000;
$colorWhite: #FFFFFF;
$colorDark: #050505;
$greyBright: #FAFAFA;
$greyDark: #616072;
$greyLight: #C4C4C4;
$greyLighter: rgba(196, 196, 196, 0.4);
$colorHippieGreen: #4E8856;
$colorGreenLabel: #3B9639;
$colorGallery: #EEEEEE;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$label: rgba(34, 51, 84, 0.7);
$label-focused: rgb(0, 171, 85);
$label-error: rgb(255, 72, 66);

$input-border: #616072;
$input-border-focused: rgb(0, 171, 85);
$input-border-error: rgb(255, 72, 66);
$input-error-text: rgb(255, 72, 66);
